import { ethers } from "ethers";
import { FACE_ADDRESS } from "./web3.service";
import CONTRACT_CONFIG from "../config/contract";

let provider, signer;
const FaceTokenAbi = CONTRACT_CONFIG.abi.face;

if (typeof window.ethereum !== "undefined") {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
}

/**
 * Face Contract Instance
 * @param {Object} role provider / signer *required
 * @returns Contract Instance
 */
const Face = (role) => {
  return new ethers.Contract(FACE_ADDRESS, FaceTokenAbi.abi, role);
};

/**
 *
 * @returns {Object} { saleStartTime, amountSale, amountMinted, isPublicSale }
 */
const fetchSaleStatus = async () => {
  const status = await Face(provider).faceSale();
  const parsed = {
    saleStartTime: parseInt(status[0]),
    amountSale: parseInt(status[1]),
    amountMinted: parseInt(status[2]),
    maxClaim: parseInt(status[3]),
    isPublicSale: status[4]
  }
  const amountLeft = parsed.amountSale - parsed.amountMinted;
  return {...parsed, amountLeft: amountLeft};
};

const fetchAccountBalance = async (account) => {
  const balance = await Face(provider).balanceOf(account);
  return parseInt(balance);
};

const fetchAvaliableToMint = async () => {
  const face = Face(provider);
  const numMinted = parseInt(await face.getNumMinted());
  const maxMint = parseInt(await face.maxMint());
  return {
    minted: numMinted,
    totalSupply: maxMint,
    available: maxMint - numMinted,
  };
};

const numberMinted = async (address) => {
  const numMinted = await Face(provider).numberMinted(address);
  return numMinted;
}


const fetchNFTOwned = async (account) => {
  const owned = await Face(provider).getOwnedTokens(account);
  return owned.map((e) => parseInt(e));
};

const unstakeItem = async (itemTokenIds, faceTokenId) => {
  const tx = await Face(signer).withdraw(itemTokenIds, faceTokenId);
  await tx.wait();
};

const transferFace = async (from, to, tokenId) => {
  const tx = await Face(signer).transferFrom(from, to, tokenId);
  await tx.wait();
  const receipt = await provider.getTransactionReceipt(tx.hash);
  return receipt;
};

const ownerOf = async (tokenId) => {
  return Face(provider).ownerOf(tokenId);
};

const setCustomName = async (tokenId, name) => {
  const tx = await Face(signer).setName(tokenId, name);
  await tx.wait();
  const newName = await Face(provider).customNames(tokenId);
  return newName;
};

const mintingPaused = async () =>{
  return Face(provider).mintingPaused();
}

const stakingPaused = async () => {
  return Face(provider).stakingPaused();
}

const claimOneFace = async(signature) => {
  const tx = await Face(signer).claimFace(signature);
  const receipt = await tx.wait();
  return receipt;
}

const checkItemStaked = async(faceId) => {
  const items = await Face(provider).stakedItems(faceId);
  return items.map(id => parseInt(id).toString());
}

const IsItemStakedToTheFace = async (faceId, itemIds) => {
  const staked = await checkItemStaked(faceId);
  let valid = true;
  itemIds.forEach(itemId => {
    if(!staked.includes(itemId)){
      valid = false;
    }
  })
  return valid;
}


export {
  fetchSaleStatus,
  fetchAccountBalance,
  fetchAvaliableToMint,
  fetchNFTOwned,
  numberMinted,
  unstakeItem,
  transferFace,
  ownerOf,
  setCustomName,
  mintingPaused,
  stakingPaused,
  claimOneFace,
  checkItemStaked,
  IsItemStakedToTheFace,
};
