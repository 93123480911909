/**
 * Context for Punk Management
 */
import React, { createContext, useReducer, useMemo } from "react";

export const ManageContext = createContext();

export const ACTIONS = {
  TOGGLE_TRANSFER_DIALOG: "toggle transfer dialog",
  TOGGLE_SET_NAME_DIALOG: "toggle set name dialog",
  SET_TRANSFER_ITEM: "toggle transfer item",
  SET_SET_NAME_ITEM: "toggle set name item",
};

function mangeReducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_TRANSFER_DIALOG: {
      return { ...state, transferDialogToggled: action.payload.toggled };
    }

    case ACTIONS.TOGGLE_SET_NAME_DIALOG: {
      return { ...state, setNameDialogToggled: action.payload.toggled };
    }

    case ACTIONS.SET_TRANSFER_ITEM: {
      return {
        ...state,
        transferItem: {
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    }

    case ACTIONS.SET_SET_NAME_ITEM: {
      return {
        ...state,
        setNameItem: {
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    }

    default: {
      throw new Error("<Manage Context> Unhandled Action Type: " + action.type);
    }
  }
}

export function ManageProvider({ children }) {
  const [manage, manageDispatch] = useReducer(mangeReducer, {
    transferDialogToggled: false,
    setNameDialogToggled: false,
    transferItem: null,
    setNameItem: null,
  });

  const value = useMemo(() => {
    return { manage, manageDispatch };
  }, [manage, manageDispatch]);

  return (
    <ManageContext.Provider value={value}>{children}</ManageContext.Provider>
  );
}
