/**
 * Context for Minting
 */
import React, { createContext, useReducer, useMemo } from "react";

export const MintContext = createContext();

export const ACTIONS = {
  TOGGLE_DIALOG: "toggle dialog", // Dialog is toggled when user has submitted a mint request
  TOGGLE_LOADING: "set loading",
  SET_STATUS: "set status",
  SET_MINTED_DATA: "set minted data",
  SET_MINT_TYPE: "set mint type",
  INITIALIZE_MINT: "initialize mint",
  FINISH_MINT: "finish mint",
  SET_MINT_FAILED: "set mint failed",
  RESET: "reset"
};

function mintReducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_DIALOG: {
      return {
        ...state,
        dialogToggled: action.payload.toggled,
      };
    }

    case ACTIONS.TOGGLE_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case ACTIONS.SET_STATUS: {
      return {
        ...state,
        status: action.payload.status,
      };
    }

    case ACTIONS.SET_MINTED_DATA: {
      return {
        ...state,
        mintedData: action.payload.mintedData,
      };
    }

    case ACTIONS.SET_MINT_TYPE :{
        return {
            ...state,
            mintType: action.payload.mintType
        }
    }

    case ACTIONS.RESET: {
        return {
            ...state,
            dialogToggled: false,
            loading: false,
            status: "",
            mintType: "",
            mintedData: [],
            mintFailed: false
        }
    }

    case ACTIONS.INITIALIZE_MINT: {
        return {
            ...state,
            dialogToggled: true,
            loading: true,
            mintType: action.payload.mintType,
            status: "Pending Transaction",
            mintFailed: false,
        }
    }

    case ACTIONS.SET_MINT_FAILED:{
        return {
          ...state,
          loading: false,
          dialogToggled: false,
          status: "Encountering Error. Please retry.",
          mintFailed: true
        }
    }

    case ACTIONS.FINISH_MINT: {
        return {
            ...state,
            minted: true,
            status: "",
            loading: false,
            mintedData: action.payload.mintedData,
            mintFailed: false
        }
    }

    default: {
      throw new Error("<Mint Context> Unhandled Action Type: " + action.type);
    }
  }
}

export function MintProvider({ children }) {
  const [mint, mintDispatch] = useReducer(mintReducer, {
    dialogToggled: false,
    loading: false,
    status: "",
    mintType: "",
    minted: false,
    mintedData: [],
    mintFailed: false
  });

  const value = useMemo(() => {
    return { mint, mintDispatch };
  }, [mint, mintDispatch]);

  return <MintContext.Provider value={value}>{children}</MintContext.Provider>;
}
