
// // PRD
import ItemTokenAbi from 'src/abi/MyPunksItem.json';
import FaceTokenAbi from 'src/abi/MyPunksFace.json';
import PunksAbi from "src/abi/Cryptopunks.json";

// DEV
// import ItemTokenAbi from "src/artifacts/contracts/MyPunksItem.sol/MyPunksItem.json";
// import FaceTokenAbi from "src/artifacts/contracts/MyPunksFace.sol/MyPunksFace.json";


const CHAIN_ID = {
  MAINNET: '0x1',
  LOCAL: '0x539',
  ROPSTEN: '0x3',
  RINKEBY: '0x4',
};

const CONTRACT = {
  address: {
    item: process.env.REACT_APP_ITEM_CONTRACT,
    face: process.env.REACT_APP_FACE_CONTRACT,
    minter: process.env.REACT_APP_MINTER_CONTRACT,
  },
  abi: {
    item: ItemTokenAbi,
    face: FaceTokenAbi,
    cryptopunks: PunksAbi,
  },
  chain: CHAIN_ID[process.env.REACT_APP_NETWORK.toUpperCase()],
  opensea: {
    test: "https://testnets.opensea.io/assets/",
    main: "https://opensea.io/assets/",
  },
};

export default CONTRACT;