import React, { createContext, useReducer, useMemo } from "react";

export const Web3Context = createContext();

export const ACTIONS = {
  TOGGLE_LOGIN_DIALOG: "toggle login dialog",
  SET_WALLET_ADDRESS: "set address",
  SET_CHAIN_ID: "set chain id",
  SET_CORRECT_NETWORK: "set correct network",
  SET_ITEM_BALANCE: "set item balance",
  SET_FACE_BALANCE: "set face balance",
  SET_ITEMS: "set items",
  SET_FACES: "set faces",
  DISCONNECT: "disconnect",
};

function web3Reducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_LOGIN_DIALOG: {
      return { ...state, loginDialogToggled: action.payload.toggled };
    }

    case ACTIONS.SET_WALLET_ADDRESS: {
      return { ...state, walletAddress: action.payload.walletAddress };
    }

    case ACTIONS.SET_CHAIN_ID: {
      return { ...state, chainId: action.payload.chainId };
    }

    case ACTIONS.DISCONNECT: {
      return { ...state, walletAddress: null, chainId: null };
    }

    case ACTIONS.SET_CORRECT_NETWORK: {
      return { ...state, correctNetwork: action.payload.isCorrect };
    }

    case ACTIONS.SET_ITEM_BALANCE: {
      return { ...state, itemBalance: action.payload.itemBalance };
    }

    case ACTIONS.SET_FACE_BALANCE: {
      return { ...state, faceBalance: action.payload.faceBalance };
    }

    case ACTIONS.SET_ITEMS: {
      return { ...state, items: action.payload.items };
    }

    case ACTIONS.SET_FACES: {
      return { ...state, faces: action.payload.faces };
    }

    default: {
      throw new Error("<Web3> Unhandled Action Type: " + action.type);
    }
  }
}

export function Web3Provider({ children }) {
  const [web3, web3Dispatch] = useReducer(web3Reducer, {
    loginDialogToggled: false,
    walletAddress: null,
    chainId: null,
    correctNetwork: true,
    itemBalance: 0,
    faceBalance: 0,
    items: [],
    faces: [],
    
  });

  const value = useMemo(() => {
    return { web3, web3Dispatch };
  }, [web3, web3Dispatch]);

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
}
