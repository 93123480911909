import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import AppLayout from "../layouts/app/";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "app",
      element: <AppLayout />,
      children: [
        { element: <AppPage />, index: true },
        { path: "mint", element: <MintPage /> },
        { path: "equip", element: <EquipPage /> },
        { path: "remove", element: <RemovePage /> },
        { path: "login", element: <LoginPage /> },
      ],
    },
    {
      path: "contest",
      element: <MainLayout />,
      children: [
        { element: <ContestGalleryPage />, index: true },
        { element: <EditorArtWorkPage />, path: "/:code" },
      ],
    },
    {
      path: "punk-editor",
      element: <MainLayout />,
      children: [{ element: <EditorPage />, index: true }],
    },
    {
      path: "tutorial",
      element: <MainLayout />,
      children: [{ element: <TutorialPage />, index: true }],
    },
    {
      path: "rarity",
      element: <MainLayout />,
      children: [{ element: <RarityPage />, index: true }],
    },
    {
      path: "gallery",
      element: <MainLayout />,
      children: [{ element: <GalleryPage />, index: true }],
    },
    {
      path: "wl",
      element: <AppLayout />,
      children: [{ path: "AmIWhitelisted", element: <CheckWL /> }],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [{ element: <LandingPage />, index: true }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const NotFound = Loadable(lazy(() => import("../pages/Page404")));
// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
// App Page
const LoginPage = Loadable(lazy(() => import("../pages/app/Login")));
const AppPage = Loadable(lazy(() => import("../pages/app")));
const MintPage = Loadable(lazy(() => import("../pages/app/Mint/Mint")));
const EquipPage = Loadable(lazy(() => import("../pages/app/Equip")));
const RemovePage = Loadable(lazy(() => import("../pages/app/Remove")));
const RarityPage = Loadable(lazy(() => import("../pages/rarity")));
const GalleryPage = Loadable(lazy(() => import("../pages/gallery")));
const CheckWL = Loadable(lazy(() => import("../pages/CheckWL")));
const TutorialPage = Loadable(lazy(() => import("../pages/Tutorial")));
const EditorPage = Loadable(lazy(() => import("../pages/editor")));
const EditorArtWorkPage = Loadable(
  lazy(() => import("../pages/editor/ArtWork"))
);
const ContestGalleryPage = Loadable(
  lazy(() => import("../pages/editor/ContestGallery"))
);
