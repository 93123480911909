import { ethers } from "ethers";
import { ACTIONS } from "../contexts/Web3Context";
import CONTRACT_CONFIG from "../config/contract";

export const ITEM_ADDRESS = CONTRACT_CONFIG.address.item;
export const FACE_ADDRESS = CONTRACT_CONFIG.address.face;
export const MINTER_ADDRESS = CONTRACT_CONFIG.address.minter;

// Switch the default network here
export const currentNetwork = CONTRACT_CONFIG.chain;

/**
 * Connect the Metamask Wallet
 * @returns {object} { account: string , chainId: int}
 */


export const connect = async (dispatch) => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const [account] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const { chainId } = await provider.getNetwork();
      const isCorrectChain = checkChainId(chainId);
      if (isCorrectChain) {
        dispatch({
          type: ACTIONS.SET_CORRECT_NETWORK,
          payload: { isCorrect: true },
        });
        dispatch({
          type: ACTIONS.SET_WALLET_ADDRESS,
          payload: { walletAddress: account },
        });
        dispatch({
          type: ACTIONS.SET_CHAIN_ID,
          payload: { chainId: chainId },
        });
      } else {
        dispatch({
          type: ACTIONS.SET_CORRECT_NETWORK,
          payload: { isCorrect: false },
        });
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: currentNetwork }],
        });
      }
      return { account: account }
    } else {
      throw new Error("No Metamask Installed.");
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const checkChainId = (id) => {
  return id === parseInt(currentNetwork);
};

