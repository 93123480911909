import { LoginAPI } from "../services/api.service";
import Cookies from "js-cookie";

export const removeTokenCookies = async () => {
  const refreshToken = Cookies.get("refreshToken");
  document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  await LoginAPI.logout(refreshToken);
  setTimeout(() => window.location.reload(), 500);
};

/**
 * Check if token is valid, if the tokens needs to refresh, then refresh it.
 */
export const validateTokens = async () => {
  const accessToken = Cookies.get("accessToken");
  const refreshToken = Cookies.get("refreshToken");
  try {
    if (accessToken && refreshToken) {
      return true;
    } else if (!accessToken && refreshToken) {
      // submit a refresh request
      const res = await LoginAPI.refreshToken(refreshToken);
      const newTokens = res.data;
      Cookies.set("accessToken", newTokens.access.token, {
        expires: new Date(newTokens.access.expires),
      });
      Cookies.set("refreshToken", newTokens.refresh.token, {
        expires: new Date(newTokens.refresh.expires),
      });
      return true;
    } else if (!accessToken && !refreshToken) {
      return false;
    }
  } catch (e) {
    console.log(e);
    throw new Error("Error in verifying login tokens.");
  }
};
