import React from "react";
import { Box, Paper, Stack, Typography } from "@material-ui/core";
import METAMASK_ICON from "../../assets/icons/metamask.svg";

export default function Web3AccountBar({ web3, balance }) {
  return (
    <Paper elevation={3} sx={{ width: "100%", textOverflow: "hidden" }}>
      <Box sx={{ padding: "20px" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <img alt="metamask" height="30px" src={METAMASK_ICON} />
            <Typography variant="overline">
              {web3.walletAddress
                ? web3.walletAddress.substring(0, 6) +
                  "..." +
                  web3.walletAddress.substring(web3.walletAddress.length - 4)
                : "Wallet Disconnected"}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <Typography variant="overline">
              Item: {balance.item}&nbsp;
            </Typography>
            <Typography variant="overline">
              Face: {balance.face}&nbsp;
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
}
