import { ACTIONS } from "../contexts/Web3Context";
import { checkChainId } from "./web3.service";
import { removeTokenCookies } from "../utils/cookies";
import Cookies from "js-cookie";

export const addWeb3Listeners = (dispatch) => {
  const eth = window.ethereum;
  if (typeof window.ethereum !== "undefined") {
    eth.on("accountsChanged", async function (account) {
      if (account.length === 0) {
        dispatch({
          type: ACTIONS.DISCONNECT,
        });
      }
      dispatch({
        type: ACTIONS.SET_WALLET_ADDRESS,
        payload: { walletAddress: account[0] },
      });

      const accessToken = Cookies.get("accessToken");
      const refreshToken = Cookies.get("refreshToken");

      if (accessToken || refreshToken) {
        await removeTokenCookies();
      } else {
        window.location.reload();
      }
    });
    eth.on("chainChanged", function (chainId) {
      const isCorrectChain = checkChainId(chainId);
      dispatch({
        type: ACTIONS.SET_CORRECT_NETWORK,
        payload: { isCorrect: isCorrectChain },
      });
      dispatch({
        type: ACTIONS.SET_CHAIN_ID,
        payload: { chainId: chainId },
      });
      window.location.reload();
    });
  }
};
