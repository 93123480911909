import React from "react";
import Lottie from "react-lottie";
import LoadingAnimation from "./loadingAnimation.json";
import Box from "@mui/material/Box";

export default function Loading({ size, sx }) {
  return (
    <Box sx={sx}>
      <Lottie
        options={{
          autoplay: true,
          loop: true,
          animationData: LoadingAnimation,
        }}
        width={size}
        height={size}
      />
    </Box>
  );
}
