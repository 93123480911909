import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: "Mint",
    path: "/app/mint"
  },
  {
    title: 'Customize',
    path: '/app',
  },
  { 
    title: 'Gallery',
    path: '/gallery'
  },
  {
    title: 'Rarity',
    path: '/rarity',
  },
  {
    title: 'Punk Editor',
    path: '/punk-editor'
  }
];

export default menuConfig;
