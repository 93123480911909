import React, { useContext } from "react";
import Stack from "@material-ui/core/Stack";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Web3Context } from "src/contexts/Web3Context";
import METAMASK_ICON from "../../assets/icons/metamask.svg";

export default function FreezeDialog() {
  const { web3 } = useContext(Web3Context);

  return (
    <div>
      <Dialog open={!web3.correctNetwork}>
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center" mb={3} sx={{color: '#e23636'}}>
            <img alt="metamask" src={METAMASK_ICON} width="30px" />
            <span>{"Wrong Network"}</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure that you connected to{" "}
            <strong>Ethereum Mainnet Network</strong>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
