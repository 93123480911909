import Router from "./routes";
import ThemeConfig from "./theme";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import Providers from "./Providers";

//toasts
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
require('dotenv').config()

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Providers>
      <ThemeConfig>
        <ThemePrimaryColor>
          <RtlLayout>
            <ScrollToTop />
            <ToastContainer />
            <Router />
          </RtlLayout>
        </ThemePrimaryColor>
      </ThemeConfig>
    </Providers>
  );
}
