/**
 * Context for Remove Page
 */
import React, { createContext, useReducer, useMemo } from "react";
import _ from "lodash";

export const RemoveContext = createContext();

export const ACTIONS = {
  TOGGLE_REMOVE_DIALOG: "toggle remove dialog",
  TOGGLE_CONFIRM_DIALOG: "toggle confirm dialog",
  ENCOUNTER_ERROR: "encounter error",
  SET_CONFIRMED: "set confirmed",
  SET_FACE_SELECTED: "set face selected",
  SET_ITEM_SELECTED: "set item selected",
  SET_UPDATED_PUNK: "set updated punk",
  DELETE_SELECTION: "delete selection",
  SET_IMAGE_RAW_DATA: "set image raw data",
  SET_STATUS: "set status",
  RESET: "reset",
};

function removeReducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_REMOVE_DIALOG: {
      return {
        ...state,
        dialogToggled: action.payload.toggled,
        dialogType: action.payload.type,
      };
    }

    case ACTIONS.TOGGLE_CONFIRM_DIALOG: {
      return {
        ...state,
        confirmDialogToggled: action.payload.toggled,
      };
    }

    case ACTIONS.SET_CONFIRMED: {
      return {
        ...state,
        confirmed: action.payload.confirmed,
      };
    }

    case ACTIONS.ENCOUNTER_ERROR: {
      return {
        ...state,
        confirmed: false,
        confirmDialogToggled: false,
      };
    }

    case ACTIONS.SET_STATUS: {
      return {
        ...state,
        status: action.payload.status,
      };
    }

    case ACTIONS.SET_FACE_SELECTED: {

      // When face is selected, recognize the parts in face
      const faceSelected = action.payload.selected;
      const updatedEquippment = {
        neck: null,
        top: null,
        eyes: null,
        skin: null,
        mouth: null,
        ear: null,
        background: null,
      };
      faceSelected.layers.forEach((part) => {
        if (faceSelected[part]) {
          updatedEquippment[part] = faceSelected[part];
        }
      });
      return {
        ...state,
        itemSelected: null,
        faceSelected: action.payload.selected,
        selectedItems: [],
        equipped: updatedEquippment,
      };
    }

    case ACTIONS.SET_ITEM_SELECTED: {
      const itemSelected = action.payload.selected;
      const temp = _.cloneDeep(state.selectedItems);
      const isExisted = temp.some((e) => e.code === itemSelected.code);
      if (!isExisted) temp.push(itemSelected);
      return {
        ...state,
        itemSelected: itemSelected,
        selectedItems: temp,
      };
    }

    case ACTIONS.SET_UPDATED_PUNK: {
      return { ...state, updatedPunk: action.payload.updated };
    }

    case ACTIONS.RESET: {
      return {
        ...state,
        dialogToggled: false,
        dialogType: null,
        faceSelected: null,
        itemSelected: null,
        updatedPunk: null,
        equipped: {
          neck: null,
          top: null,
          eyes: null,
          skin: null,
          mouth: null,
          ear: null,
          background: null,
        },
        status: "",
        selectedItems: [],
        imageRawData: [],
      };
    }

    case ACTIONS.DELETE_SELECTION: {
      const itemCode = action.payload.itemCode;
      const temp = _.cloneDeep(state.selectedItems);
      const filtered = temp.filter((e) => e.code !== itemCode);
      // Set item selected to null to disable re-rendering of repetitive items, eg. backgrounds
      return { ...state, selectedItems: filtered };
    }

    case ACTIONS.SET_IMAGE_RAW_DATA: {
      return { ...state, imageRawData: action.payload.imageRawData };
    }

    default: {
      throw new Error("<Remove Context> Unhandled Action Type: " + action.type);
    }
  }
}

export function RemoveProvider({ children }) {
  const [remove, removeDispatch] = useReducer(removeReducer, {
    dialogToggled: false,
    dialogType: null,
    confirmDialogToggled: false,
    confirmed: false,
    updatedPunk: null,
    faceSelected: null,
    itemSelected: null,
    equipped: {
      neck: null,
      top: null,
      eyes: null,
      skin: null,
      mouth: null,
      ear: null,
      background: null,
    },
    status: "",
    selectedItems: [],
    imageRawData: [],
  });

  const value = useMemo(() => {
    return { remove: remove, removeDispatch: removeDispatch };
  }, [remove, removeDispatch]);

  return (
    <RemoveContext.Provider value={value}>{children}</RemoveContext.Provider>
  );
}
