/**
 * Context for Punk Management
 */
import React, { createContext, useReducer, useMemo } from "react";

export const RarityContext = createContext();

export const ACTIONS = {
  SET_DATA: "set data",
  SET_SELECTED_PART: "set selected part",
};

function mangeReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return { ...state, data: action.payload.data };
    }

    case ACTIONS.SET_SELECTED_PART: {
      return { ...state, selectedPart: action.payload.selectedPart };
    }

    default: {
      throw new Error("<Manage Context> Unhandled Action Type: " + action.type);
    }
  }
}

export function RarityProvider({ children }) {
  const [rarity, rarityDispatch] = useReducer(mangeReducer, {
    data: [],
    selectedPart: "",
  });

  const value = useMemo(() => {
    return { rarity, rarityDispatch };
  }, [rarity, rarityDispatch]);

  return (
    <RarityContext.Provider value={value}>{children}</RarityContext.Provider>
  );
}
