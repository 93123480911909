import API from "../config/api";
import Cookies from "js-cookie";
import { FACE_ADDRESS } from "./web3.service";
import axios from "axios";
import FormData from "form-data";
import { uid } from "src/utils/random";

export const ItemAPI = {
  mint: async function (receipt) {
    return API.post("/ai/mint/item", { receipt: receipt });
  },
  batchMint: async function (receipt) {
    return API.post("/ai/mint/batch/item", {
      receipt: receipt,
    });
  },
  getItemMetadata: async function (_tokenId) {
    return API.get(`/metadata/item/${_tokenId}?full=true`);
  },
  getItemMetadataBatch: async function ({ _tokenIds, full }) {
    return API.post(`/metadata/item`, { tokenIds: _tokenIds, full: full });
  },
};

export const FaceAPI = {
  mint: async function (receipt, signature, address) {
    return API.post("/ai/mint/face", {
      receipt: receipt,
      signature: signature,
      address: address,
    });
  },
  getFaceMetadata: async function (tokenId) {
    return API.get(`/metadata/face/${tokenId}?full=true`);
  },
  getFaceMetadataBatch: async function ({ _tokenIds, full }) {
    return API.post("/metadata/face", { tokenIds: _tokenIds, full: full });
  },
  stake: async function (itemIds, faceId, imageRawData, layers) {
    return API.post(
      "/staking/stake",
      {
        itemIds: itemIds,
        faceId: faceId,
        imageRawData,
        layers: layers,
      },
      {
        headers: { Authorization: `bearer ${Cookies.get("accessToken")}` },
      }
    );
  },
  unstake: async function (itemIds, faceId, imageRawData) {
    return API.post(
      "/staking/unstake",
      {
        itemIds: itemIds,
        faceId: faceId,
        imageRawData: imageRawData,
      },
      { headers: { Authorization: `bearer ${Cookies.get("accessToken")}` } }
    );
  },
};

export const LoginAPI = {
  getNonce: async function (address) {
    return API.get(`/web3/user/nonce?publicAddress=${address}`);
  },
  signUp: async function (address) {
    return API.post("/web3/user/signup", { publicAddress: address });
  },
  auth: async function (address, _signature) {
    return API.post("/web3/user/auth", {
      publicAddress: address,
      signature: _signature,
    });
  },
  logout: async function (_refreshToken) {
    return API.post("/web3/user/logout", { refreshToken: _refreshToken });
  },
  refreshToken: async function (_refreshToken) {
    return API.post("/web3/user/refresh", { refreshToken: _refreshToken });
  },
};

export const AirdropAPI = {
  registerCryptoPunks: async function (_address) {
    return API.post("/airdrop/regCryptoPunks", { address: _address });
  },
  isOuroReg: async function (_address) {
    return API.post("/airdrop/isOuroReg", { address: _address });
  },
  mintOuroItem: async function (_tokenId, _address) {
    return API.post("/airdrop/mintOuroItem", {
      tokenId: _tokenId,
      address: _address,
    });
  },
  mintOuroFace: async function (_tokenId, _address) {
    return API.post("/airdrop/mintOuroFace", {
      tokenId: _tokenId,
      address: _address,
    });
  },
};

/**
 * API to retrive and check WL
 */
export const Web3API = {
  isUserWhitelisted: async function (address) {
    return API.post("/web3/wl/isAddrWhitelisted", { userAddress: address });
  },

  getWLSignature: async function (address) {
    return API.post("/web3/wl/getSignature", { userAddress: address });
  },
};

export const RarityAPI = {
  getStats: async function () {
    return API.get("/gallery/getGallery");
  },
};

export const GalleryAPI = {
  getCombinations: async function () {
    return API.get("/gallery/getCombinations");
  },
  getLeaderBoard: async function () {
    return API.get("/gallery/leaderboard");
  },
};

export const EditorAPI = {
  getEditorItems: async function () {
    return API.get("/gallery/editorItems");
  },
  submitArt: async function (artData) {
    return API.post('/editor/submitArt', artData);
  },
  submitTwitterHandle: async function(code, twitterHandle){
    return API.post('/editor/updateTwitterHandle', { code: code, twitterHandle: twitterHandle });
  },
  getArt: async function(code){
    return API.get(`/editor/getArt/${code}`);
  },
  getAllArts: async function(){
    return API.get('/editor/getAllArts');
  },
  likeArt: async function(code){
    return API.post('/editor/likeArt', { code: code });
  },
  unlikeArt: async function (code){
    return API.post('/editor/unlikeArt', { code: code });
  }
};

export const PinataAPI = {
  uploadPreviewImage: async function (canvas) {
    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
    const file = new File([blob], `${uid()}.jpg`, { type: "image/jpeg" });
    const data = new FormData();
    data.append("file", file);
    const res = await axios.post(
      "https://api.pinata.cloud/pinning/pinFileToIPFS",
      data,
      {
        maxBodyLength: "Infinity",
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          Authorization:
            `Bearer ${process.env.REACT_APP_PINATA_AUTH}`,
        },
      }
    );
    if (res.data) {
      return res.data;
    } else {
      throw new Error("Unable to upload");
    }
  },
};

export const upload = async (blob, code) => {
  const file = new File([blob], `${code}.jpeg`);
  let data = new FormData();
  data.append("file", file);
  return await API.post("/ai/upload", data, {
    headers: { "Content-Type": "multipart/form-data" },
    timeout: 60000,
  });
};

export const forceUpdateOpenSea = async (faceId) => {
  try {
    await axios.get(
      `https://api.opensea.io/api/v1/asset/${FACE_ADDRESS}/${faceId}/?force_update=true`
    );
    console.log("OpenSea force updated.");
  } catch (e) {
    console.error("OpenSea Force Update Failed");
  }
};

export const getCurrentGas = async () => {
  try {
    const currentGasRes = await axios.get(
      `https://ethgasstation.info/api/ethgasAPI.json??api-key=${process.env.REACT_APP_ETH_GAS_API}`
    );
    const ethPriceRes = await axios.get(
      "https://api.coinbase.com/v2/prices/eth-usd/buy"
    );
    const avgGwei = currentGasRes.data.average / 10;
    const ethPrice = parseFloat(ethPriceRes.data.data.amount);
    const Gwei = 0.000000001;
    const gweiToUsd = avgGwei * Gwei * ethPrice;
    return {
      oneItem: (81367 * gweiToUsd).toFixed(2),
      twoItem: (83758 * gweiToUsd).toFixed(2),
      threeItem: (86149 * gweiToUsd).toFixed(2),
      tenItem: (102886 * gweiToUsd).toFixed(2),
    };
  } catch (e) {
    console.log(e);
    console.error("Gas Estimation Fetch Failed.");
  }
};
