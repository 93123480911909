import {
  Divider,
  Container,
  Typography,
  Stack,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import LAB_LOGO from "../../assets/uintlab.png";
import LOGO from "../../assets/logo.svg";
import RARITY_SNIPER from "../../assets/rarity-sniper.167a4cd9.png";

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  paddingTop: "80px",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 2, pb: 2 }}>
        <Stack spacing={1} py={3}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start '}}>
            <img alt="logo"src={LOGO} width="100px" />
            <img alt="uintlab" src={LAB_LOGO} width="100px" />
            <a href="https://raritysniper.com/">
              <img alt="rarity-sniper" src={RARITY_SNIPER} width="70px" />
            </a>
          </Stack>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Uint Labs © 2022. All rights reserved. Not affiliated with Larva
            Labs.
          </Typography>
        </Stack>
      </Container>
    </RootStyle>
  );
}
