/**
 * Context for PUNK EDITOR
 */
import React, { createContext, useReducer, useMemo } from "react";
import _ from "lodash";

export const EditorContext = createContext();

export const ACTIONS = {
  SET_DATA: "set data",
  SET_FILTERED: "set filtered",
  SET_IMAGE_RAW_DATA: "set image raw data",
  SET_SELECTED_ITEMS: "set selected items",
  ADD_ITEM: "Add Item",
  ADD_FACE: "Add face",
  DELETE_ITEM: "delete item",
  CLEAR: "clear",
  TOGGLE_SHARE_DIALOG: "toggle share dialog"
};

function editorReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return { ...state, data: action.payload.data };
    }

    case ACTIONS.SET_FILTERED: {
      return { ...state, filtered: action.payload.filtered };
    }

    case ACTIONS.SET_IMAGE_RAW_DATA: {
      return { ...state, imageRawData: action.payload.imageRawData };
    }

    case ACTIONS.ADD_FACE: {
      return { ...state, faceSelected: action.payload.face };
    }

    case ACTIONS.SET_SELECTED_ITEMS: {
      return { ...state, selectedItems: action.payload.selectedItems };
    }

    case ACTIONS.CLEAR: {
      return { ...state, selectedItems: [], faceSelected: null };
    }

    case ACTIONS.ADD_ITEM: {
      const item = action.payload.item;

      // 1. Updated Selected Items and delete the one with the same part
      const selectedItems = _.cloneDeep(state.selectedItems);
      if (selectedItems.length > 0) {
        for (let i = 0; i < selectedItems.length; i++) {
          if (
            selectedItems[i].parts.toLowerCase() === item.parts.toLowerCase()
          ) {
            selectedItems[i] = item;
            break;
          } else if (i === selectedItems.length - 1) {
            // If it's the last one, but still not replacement, then add it
            console.log("YES");
            selectedItems.push(item);
          }
        }
      } else {
        selectedItems.push(item);
      }

      return { ...state, selectedItems: selectedItems };
    }

    case ACTIONS.DELETE_ITEM: {
      const temp = state.selectedItems;
      return {
        ...state,
        selectedItems: temp.filter((e) => e.name !== action.payload.item.name),
      };
    }

    case ACTIONS.TOGGLE_SHARE_DIALOG: {
        return {
            ...state,
            shareDialogToggled: action.payload.toggled
        }
    }  

    default: {
      throw new Error("<Editor Context> Unhandled Action Type: " + action.type);
    }
  }
}

export function EditorProvider({ children }) {
  const [editor, editorDispatch] = useReducer(editorReducer, {
    data: [],
    filtered: [],
    imageRawData: [],
    faceSelected: null,
    selectedItems: [], //List of selected Items
    shareDialogToggled: false
  });

  const value = useMemo(() => {
    return { editor, editorDispatch };
  }, [editor, editorDispatch]);

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
}
