import React, { createContext, useReducer, useMemo } from "react";
import _ from "lodash";

export const EquipContext = createContext();

export const ACTIONS = {
  TOGGLED_DIALOG: "toggle dialog",
  TOGGLE_CONFIRM_DIALOG: "toggle confirm dialog",
  ENCOUNTER_ERROR: "encounter error",
  SET_EQUIP_FAILED: "set equip failed",
  SET_RETRY: "set retry",
  SET_CONFIRMED: "set confirmed",
  SET_DIALOG_TYPE: "set dialog type",
  SET_FACE_SELECTED: "set face selected",
  SET_ITEM_SELECTED: "set item selected",
  SET_SELECTED_ITEMS: "set selected items",
  SET_EQUIPPED: "set equipped",
  DELETE_EQUIPPED: "delete equipped",
  SET_LAYERS: "set layers",
  SET_UPDATED_PUNK: "set updated punk",
  SET_IMAGE_RAW_DATA: "set image raw data",
  SET_STATUS: "set status",
  RESET: "reset",
};

function equipReducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLED_DIALOG: {
      return {
        ...state,
        dialogToggled: action.payload.toggled,
        dialogType: action.payload.type,
      };
    }

    case ACTIONS.TOGGLE_CONFIRM_DIALOG: {
      return {
        ...state,
        confirmDialogToggled: action.payload.toggled,
      };
    }

    case ACTIONS.SET_CONFIRMED: {
      return {
        ...state,
        confirmed: action.payload.confirmed,
      };
    }

    case ACTIONS.ENCOUNTER_ERROR: {
      return {
        ...state,
        confirmed: false,
        confirmDialogToggled: false,
      };
    }

    case ACTIONS.SET_EQUIP_FAILED: {
      return {
        ...state,
        equipFailed: action.payload.failed
      }
    }

    case ACTIONS.SET_RETRY:{
      return {
        ...state,
        retry: action.payload.retry
      }
    }

    case ACTIONS.SET_DIALOG_TYPE: {
      return { ...state, dialogType: action.payload.type };
    }

    case ACTIONS.SET_FACE_SELECTED: {
      // When face is selected, recognize the parts in face
      const faceSelected = action.payload.selected;
      const updatedEquippment = {
        neck: null,
        top: null,
        eyes: null,
        skin: null,
        mouth: null,
        ear: null,
        background: null,
      };
      faceSelected.layers.forEach((part) => {
        if (faceSelected[part]) {
          updatedEquippment[part] = faceSelected[part];
        }
      });
      return {
        ...state,
        itemSelected: null,
        faceSelected: action.payload.selected,
        layers: faceSelected.layers,
        equipped: updatedEquippment,
      };
    }

    case ACTIONS.SET_ITEM_SELECTED: {
      const itemSelected = action.payload.selected;
      // When each item selected, we will recognize the part
      const partName = itemSelected.bodyParts.name.toLowerCase();
      const updatedEquippment = _.cloneDeep(state.equipped);
      // If there is a previous selection, delete it
      // if (state.itemSelected) {
      //   delete updatedEquippment[
      //     state.itemSelected.bodyParts.name.toLowerCase()
      //   ];
      // }
      updatedEquippment[partName] = itemSelected;

      return {
        ...state,
        itemSelected: itemSelected,
        equipped: updatedEquippment,
      };
    }

    case ACTIONS.SET_SELECTED_ITEMS: {
      return {
        ...state,
        selectedItems: action.payload.selectedItems
      }
    }

    case ACTIONS.SET_EQUIPPED: {
      return { ...state, equipped: action.payload.equipped };
    }

    case ACTIONS.DELETE_EQUIPPED: {
      const partToDelete = action.payload.partToDelete;
      const temp = _.cloneDeep(state.equipped);
      temp[partToDelete] = null;
      // Set item selected to null to disable re-rendering of repetitive items, eg. backgrounds
      return { ...state, equipped: temp, itemSelected: null };
    }

    case ACTIONS.SET_LAYERS: {
      return { ...state, layers: action.payload.layers };
    }

    case ACTIONS.SET_UPDATED_PUNK: {
      return { ...state, updatedPunk: action.payload.updated };
    }

     case ACTIONS.SET_STATUS: {
      return {
        ...state,
        status: action.payload.status,
      };
    }

    case ACTIONS.RESET: {
      return {
        ...state,
        dialogToggled: false,
        dialogType: null,
        confirmDialogToggled: false,
        equipFailed: false,
        retry: false,
        confirmed: false,
        updatedPunk: null,
        faceSelected: null,
        itemSelected: null,  
        selectedItems: [],   
        equipped: {
          neck: null,
          top: null,
          eyes: null,
          skin: null,
          mouth: null,
          ear: null,
          background: null,
        },
        status: "",
        imageRawData: [],
      };
    }

    case ACTIONS.SET_IMAGE_RAW_DATA: {
      return { ...state, imageRawData: action.payload.imageRawData };
    }

    default: {
      throw new Error("<Equip Context> Unhandled Action Type: " + action.type);
    }
  }
}

export function EquipProvider({ children }) {
  const [equip, equipDispatch] = useReducer(equipReducer, {
    dialogToggled: false,
    dialogType: null,
    confirmDialogToggled: false,
    equipFailed: false,  // error dialog toggle
    retry: false, //retry toggle
    confirmed: false,
    // updated punk only set after merge item to face
    updatedPunk: null,
    faceSelected: null,
    itemSelected: null,   //Last selected Item
    selectedItems: [],    //List of selected Items
    equipped: {
      neck: null,
      top: null,
      eyes: null,
      skin: null,
      mouth: null,
      ear: null,
      background: null,
    },
    status: "",
    layers: [],
    imageRawData: [],
  });

  const value = useMemo(() => {
    return { equip, equipDispatch };
  }, [equip, equipDispatch]);

  return (
    <EquipContext.Provider value={value}>{children}</EquipContext.Provider>
  );
}
