import { ethers } from "ethers";
import { ITEM_ADDRESS } from "./web3.service";
import CONTRACT_CONFIG from "../config/contract";

let provider, signer;
const ItemTokenAbi = CONTRACT_CONFIG.abi.item;

if (typeof window.ethereum !== "undefined") {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
}

/**
 * Face Contract Instance
 * @param {Object} inject provider / signer *required
 * @returns Contract Instance
 */
const Item = (inject) => {
  return new ethers.Contract(ITEM_ADDRESS, ItemTokenAbi.abi, inject);
};

/**
 * Check If the Staking is paused
 * @return  {Boolean}
 */
const stakingPaused = async () => {
  return Item(provider).stakingPaused();
};

/**
 * Check If the Minting is paused
 * @returns {Boolean}
 */
const mintingPaused = async () => {
  return Item(provider).mintingPaused();
};

/**
 * Fetch the Item Sale Status
 * @returns {Object} saleStartTime, price, amountSale, amountMinted, isPublicSale
 */
const fetchSaleStatus = async () => {
  const status = await Item(provider).getCurrentSale();
  const parsed = {
    saleStartTime: parseInt(status[0]),
    price: ethers.utils.formatEther(status[1]),
    amountSale: parseInt(status[2]),
    amountMinted: parseInt(status[3]),
    isPublicSale: status[4],
  };
  const amountLeft = parsed.amountSale - parsed.amountMinted;
  return { ...parsed, amountLeft: amountLeft };
};

// ############### Minting ###############

const mintItem = async (amount) => {
  const currentSale = await fetchSaleStatus();
  const overrides = {
    value: ethers.utils.parseEther((parseFloat(currentSale.price) * amount).toString()),
  };
  const tx = await Item(signer).mintItem(amount, overrides);
  const receipt = await tx.wait();
  return receipt;
};

/**
 * Stake Items into a single face
 * @param   {Array}  tokenIds
 * @param   {Int}    faceId
 */
const stakeItem = async (tokenIds, faceId) => {
  const tx = await Item(signer).stakeItem(tokenIds, faceId);
  await tx.wait();
};

/**
 * Get Account Balance of ITEM Token
 * @returns {Integer} balance
 */
const fetchAccountBalance = async (account) => {
  const balance = await Item(provider).balanceOf(account);
  return parseInt(balance);
};

/**
 * Fetch TokenIds from a specific account by enumerating in contract
 * @param   {String}  account  Account Address
 * @return  {Array}
 */
const fetchNFTOwned = async (account) => {
  const owned = await Item(provider).getOwnedTokens(account);
  return owned.map((e) => parseInt(e));
};

/**
 * Transfer Item to Another Address
 * @param   {String}  from
 * @param   {String}  to
 * @param   {Int}     tokenId
 * @return  {Object}
 */
const transferItem = async (from, to, tokenId) => {
  const tx = await Item(signer).transferFrom(from, to, tokenId);
  await tx.wait();
  const receipt = await provider.getTransactionReceipt(tx.hash);
  return receipt;
};

/**
 * Get the Owner of a specific token Id
 * @param   {Int}  tokenId
 * @return  {Promise}
 */
const ownerOf = async (tokenId) => {
  return Item(provider).ownerOf(tokenId);
};

/**
 * 
 * @param {Array} itemIds 
 * @param {Address} ownerAddr 
 * @returns 
 */
const IsItemUnstaked = async (itemIds, ownerAddr) => {
  let valid = true;
  for(let i=0; i<itemIds.length; i++){
    const address = await ownerOf(itemIds[i]);
    if(address.toLowerCase() !== ownerAddr.toLowerCase()){
      valid = false;
    }
  }
  return valid;
}

export {
  stakingPaused,
  mintingPaused,
  mintItem,
  stakeItem,
  fetchAccountBalance,
  fetchNFTOwned,
  transferItem,
  ownerOf,
  fetchSaleStatus,
  IsItemUnstaked
};
