import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import LOGO from "../assets/logo.png";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 200, height: 30, ...sx }}>
      <img alt="logo" width={200} src={LOGO} />
    </Box>
  );
}
