/**
 * Context for Gallery
 */
 import React, { createContext, useReducer, useMemo } from "react";

 export const GalleryContext = createContext();
 
 export const ACTIONS = {
    TOGGLE_MODAL: 'toggle modal',
    SET_TRAIT_DATA: 'set trait data',
    SET_RARITY_RANK: "set rarity leaderboard"
 };
 
 function galleryReducer(state, action) {
   switch (action.type) {
     case ACTIONS.SET_TRAIT_DATA: {
         return { ...state, traitData: action.payload.data };
    }
 
     case ACTIONS.TOGGLE_MODAL: {
         return { ...state, modalToggled: action.payload.toggled };
    }

    case ACTIONS.SET_RARITY_RANK:{
        return { ...state, rarityRank: action.payload.rarityRank };
    }
 
     default: {
       throw new Error("<Gallery Context> Unhandled Action Type: " + action.type);
     }
   }
 }
 
 export function GalleryProvider({ children }) {
   const [gallery, galleryDispatch] = useReducer(galleryReducer, {
     traitData: {},
     modalToggled: false,
     rarityRank: [],
   });
 
   const value = useMemo(() => {
     return { gallery, galleryDispatch };
   }, [gallery, galleryDispatch]);
 
   return (
     <GalleryContext.Provider value={value}>{children}</GalleryContext.Provider>
   );
 }
 