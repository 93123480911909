import React, { useContext, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@material-ui/core";
import MainNavbar from "../main/MainNavbar";
import { addWeb3Listeners } from "../../services/web3.listeners";
import Web3AccountBar from "../../components/Web3AccountBar";
import { connect } from "../../services/web3.service";
import { Web3Context, ACTIONS } from "src/contexts/Web3Context";
import { toast } from "react-toastify";
import FreezeDialog from "../../components/Dialogs/FreezeDialog";

// Contract
import * as ItemContract from "../../services/web3.contract.item";
import * as FaceContract from "../../services/web3.contract.face";

// ----------------------------------------------------------------------

export default function AppLayout() {
  const { web3, web3Dispatch } = useContext(Web3Context);
  const [balance, setBalance] = useState({});

  useEffect(() => {
    async function fetchAccountData() {
      try {
        const { account } = await connect(web3Dispatch);
        const ItemBalance = await ItemContract.fetchAccountBalance(account);
        const FaceBalance = await FaceContract.fetchAccountBalance(account);
        setBalance({ item: ItemBalance, face: FaceBalance });
        web3Dispatch({
          type: ACTIONS.SET_ITEM_BALANCE,
          payload: { itemBalance: ItemBalance }
        });
        web3Dispatch({
          type: ACTIONS.SET_FACE_BALANCE,
          payload: { faceBalance: FaceBalance }
        });
      } catch (e) {
        console.error(e);
      }
    }
    if (typeof window.ethereum !== "undefined") {
      fetchAccountData();
      addWeb3Listeners(web3Dispatch);
    } else {
      toast.error("Please Install Metamask");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FreezeDialog />
      <MainNavbar />
      <Container>
        <Box sx={{ pt: 15 }}>
          <Web3AccountBar web3={web3} balance={balance} />
        </Box>
        <div>
          <Outlet />
        </div>
      </Container>
    </>
  );
}
