import React from "react";
import { Web3Provider } from "./contexts/Web3Context";
import { EquipProvider } from "./contexts/EquipContext";
import { RemoveProvider } from "./contexts/RemoveContext";
import { ManageProvider } from "./contexts/ManageContext";
import { MintProvider } from "./contexts/MintContext";
import { CookiesProvider } from "react-cookie";
import { RarityProvider } from "./contexts/RarityContext";
import { GalleryProvider } from "./contexts/GalleryContext";
import { EditorProvider } from "./contexts/EditorContext";

export default function Providers({ children }) {
  return (
    <CookiesProvider>
      <Web3Provider>
        <EditorProvider>
          <GalleryProvider>
            <RarityProvider>
              <ManageProvider>
                <RemoveProvider>
                  <EquipProvider>
                    <MintProvider>{children}</MintProvider>
                  </EquipProvider>
                </RemoveProvider>
              </ManageProvider>
            </RarityProvider>
          </GalleryProvider>
        </EditorProvider>
      </Web3Provider>
    </CookiesProvider>
  );
}
